import React from 'react'
import styles from './Steps.module.css';
import logo1 from '../images/logo1.png';
import logo2 from '../images/logo2.png';
import logo3 from '../images/logo3.png';
import logo4 from '../images/logo4.png';
function Steps() {
    return (
        <div className={styles.body}>
            <h1>كيف تعمل</h1>
            <div className={styles.container}>
                <div className={styles.element}>
                <img src={logo1} alt='logo1'></img>
                    <h3>الخطوة 1</h3>
                    <p>أرسل لنا التفاصيل الخاصة بك عن طريق ملء النموذج</p>
                </div>
                <div className={styles.element}>
                <img src={logo2} alt='logo1'></img>
                    <h3>الخطوة 2</h3>
                    <p>سوف نعاود الاتصال بك في غضون 48 ساعة</p>
                </div>
                <div className={styles.element}>
                <img src={logo3} alt='logo1'></img>
                    <h3>الخطوه 3</h3>
                    <p>احصل على استقبال من قبلنا في الهند و</p>
                    <p>احصل على المساعدة الطبية هناك</p>
                </div>
                <div className={styles.element}>
                <img src={logo4} alt='logo1'></img>
                    <h3>الخطوة 4</h3>
                    <p>احصل على العلاج وعد إلى المنزل</p>
                </div>
            </div>
        </div>
    )
}

export default Steps
