import React from "react";
import styles from "./styles.module.css";

function ObesitySurgery() {
  return (
    <div className={styles.container}>
      <h1>Obesity Surgery</h1>
      <p>
        على الرغم من أن السمنة ليست مرضًا في حد ذاتها ، إلا أنها تخلق مسارات
        للعديد من الأمراض القاتلة والخطيرة الأخرى التي تصيب أجسام البشر. وبما أن
        الأطباء والأطباء في الهند يستعدون لمواجهة جميع أنواع الأمراض ، فقد
        توصلوا إلى بنية تحتية مناسبة لجراحة السمنة في الهند. كما ذكرنا من قبل ،
        السمنة مشكلة عالمية والآن بدأ الناس يدركون مظاهرها الخطيرة
      </p>
      <p>
        نتيجة لذلك ، كلهم ​​يريدون القضاء على هذه الظاهرة الخطيرة في مهدها. ومن
        أجل القيام بذلك ، يختار الأشخاص من جميع أنحاء العالم إجراء جراحة السمنة
        في الهند ، والتي ظهرت مؤخرًا كملاذ للسياحة العلاجية العالمية ، وذلك بفضل
        بنيتها التحتية والمرافق المحسنة بشكل كبير في جميع المجالات تقريبًا
      </p>
      <p>
        الآن ، بعد أن فهمنا تلك المخاطر العديدة المميتة التي تمثلها السمنة
        لأولئك الذين يعانون منها ، أصبح من الضروري الآن بالنسبة لنا أن نفهم
        كيفية علاجها قبل أن نناقش أي مزيد من التفاصيل حول موضوع جراحة السمنة في
        الهند. السمنة ، في صورتها المعتدلة ، يمكن علاجها بسهولة عن طريق ممارسة
        التمارين الرياضية بانتظام واتباع نظام غذائي متوازن واتباع نمط حياة صحي
        شامل
      </p>
      <p>
        وفي هذه الحالة تصبح جراحة السمنة ، أو جراحة الشيخوخة كما هو معروف ،
        الخيار الوحيد لمن يعاني منها. ويجب أن يكون هذا بالإضافة إلى نمط حياة صحي
        يجب على المريض أيضًا اتباعه. تنمو خدمات الرعاية الصحية في الهند اليوم
        على قدم وساق ولا ينبغي أن يكون سببًا للدهشة أن جراحة السمنة في الهند
        أصبحت أيضًا شائعة جدًا بين عامة الناس على مستوى العالم
      </p>
      <p>
        أحد الأسباب الرئيسية التي تجعل الناس من جميع أنحاء العالم يفضلون جراحة
        السمنة في الهند هو أن تكاليف الرعاية الصحية والإجراءات الجراحية أقل
        بكثير في الهند مقارنة بالتكاليف في البلدان الأخرى في جميع أنحاء العالم.
        تتوفر هنا أيضًا مرافق أفضل ، بالإضافة إلى الإجراءات الجراحية ، هناك
        وسائل راحة كاملة وممتازة لاستشفاء المرضى أيضًا. نتيجة لكل هذه المزايا
        التي تقدمها الهند ، يتطلع الناس بشكل متزايد إلى إجراء جراحة السمنة في
        الهند
      </p>
    </div>
  );
}

export default ObesitySurgery;
