import React from "react";
import styles from "./styles.module.css";

function OrthopaedicSurgery() {
  return (
    <div className={styles.container}>
      <h1>Orthopaedi procedures</h1>
      <p>
        يتوفر عدد من إجراءات تقويم العظام مثل استبدال مفصل الورك والركبة ،
        وتقنية إليزاروف ، وإطالة الأطراف ، وتقنية برمنغهام لإعادة تسطيح الورك
        (والتي تحقق نتائج أعلى من عمليات استبدال مفصل الورك التقليدية ولا تزال
        غير متوفرة حتى في الولايات المتحدة) إلخ.
      </p>
      <p>
        تتخصص العديد من المستشفيات في أحدث التقنيات والعلاجات مثل الجراحة طفيفة
        التوغل وزرع الغضاريف والعظام وجراحة العمود الفقري وجراحة تجنيب الأطراف.
        يتم علاج جميع أنواع مشاكل العضلات والهيكل العظمي ، بدءًا من التهاب
        المفاصل إلى الإصابات الرياضية ، وحتى كسور العظام المعقدة وأورام العظام
        وحالات الطفولة مثل الجنف بشكل أكثر فاعلية
      </p>
      <h1>Bone Marrow Transplant</h1>
      <p>
        المستشفيات الرئيسية في الهند لديها وحدات الأورام التي تضم جراحة الأورام
        والعلاج الطبي والإشعاعي بالإضافة إلى زرع نخاع العظام المهم. ساعدت وحدة
        زرع النخاع العظمي المزودة بمرشحات هيبا ذات الضغط العالي في تحقيق معدل
        نجاح مرتفع للغاية في مختلف أنواع الزرع
      </p>
      <p>
        تم إجراء عملية زرع دم الحبل السري وزراعة الخلايا الجذعية غير المتطابقة
        بنجاح ، وهو إنجاز رائع وهام ، مع الأخذ في الاعتبار حقيقة أن العلاج يكلف
        عُشر ما يفعله في الغرب. يتوفر جراحون خاصون للأعضاء الفردية. يقدم جراحو
        التجميل المشهورون العلاج لسرطان الرأس والعنق وسرطان الثدي والأورام
        الخبيثة الأخرى. تشمل التسهيلات المقدمة العلاج عن بعد والذي يشمل محطات
        عمل المحاكاة لضمان الدقة العالية والسلامة أثناء العلاج في المسرع الخطي
        أو آلات الكوبالت ، والعلاج العضدي وأنظمة التخطيط ثلاثية الأبعاد. في
        جراحة العظام ، تتم ممارسة تقنية إليزاروف لعلاج تشوهات الأطراف وتقصير
        الأطراف وتشوهها
      </p>
      <h1>Joint Replacement Surgery</h1>
      <p>
        يتم إجراء جراحة استبدال الكتف / الورك وجراحة استبدال الركبة الثنائية
        باستخدام أحدث ثقب المفتاح أو الجراحة بالمنظار والتنظير في العديد من
        المستشفيات في الهند بما في ذلك مستشفى أبولو ومستشفى سير جانجا رام
        ومستشفى العائلة المقدسة في دلهي ومستشفى بومباي ومستشفى ليلافاتي وهيندوجا
        في مومباي ومعهد مدراس لطب العظام وعلوم الصدمات
      </p>
      <p>
        بعض المستشفيات مثل أبولو في دلهي تقارن بأفضل المستشفيات في الولايات
        المتحدة والمملكة المتحدة. تبلغ تكلفة استبدال مفصل الركبة حوالي 3000 جنيه
        في الهند حيث توجد في المملكة المتحدة ، وتتكلف عملية جراحية مماثلة
        باستخدام نفس الغرسات والمواد الاستهلاكية الطبية حوالي 10000 جنيه
      </p>
    </div>
  );
}

export default OrthopaedicSurgery;
