import React from "react";
import styles from "./Treatment.module.css";
import treatment from "./images/treatment.png";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

/* */
import heart_care from "./treatment_images/heart_care.jpg";
import cosmetic_surgery from "./treatment_images/cosmetic_surgery.jpg";
import ivf from "./treatment_images/ivf.PNG";
import dental_care from "./treatment_images/dental_care.jpg";
import gynaecologyy from "./treatment_images/gynecology.png";
import health_care from "./treatment_images/health_care.PNG";
import orthopaedic_surgery from "./treatment_images/othopedic_surgery.jpg";
import baby_delivery from "./treatment_images/baby_delivery.jpg";
import bone_marrow_transplant from "./treatment_images/bone_marrow_transplant.PNG";
import joint_replacement_surgery from "./treatment_images/joint_replacement_surgery.jpg";
import neuro_surgery from "./treatment_images/neuro_surgery.jpg";
import breast_implants from "./treatment_images/breast_implants.jpg";
import breast_reduction_surgery from "./treatment_images/breast_reduction_surgery.jpg";
import diabetes_treatment from "./treatment_images/diabetes_treatment.jpg";
import ent_care from "./treatment_images/ent_care.jpg";
import eye_care from "./treatment_images/eye_care.jpg";
import fat_grafts from "./treatment_images/fat_grafts_plastic_surgery.jpg";
import foot_surgery from "./treatment_images/foot_surgery.jpg";
import skin_treatment from "./treatment_images/skin_treatment.jpg";
import hernia_operation from "./treatment_images/hernia_operation.PNG";
import kidney_transplant from "./treatment_images/kidney_transplant.jpg";
import liposuction from "./treatment_images/liposuction.jpg";
import liver_transplant from "./treatment_images/liver_transplant.jpg";
import obesity_surgery from "./treatment_images/obesity_surgery.jpg";



function Treatment() {
  return (
    <div className={styles.group}>
      <div className={styles.container}>
        <div className={styles.pair}>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={heart_care} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>رعاية القلب</Card.Text>
              <Link exact to="/health_care">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={cosmetic_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>الجراحة التجميلية</Card.Text>
              <Link exact to="/cosmetic_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
         
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={ivf} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>علاج أطفال الأنابيب</Card.Text>
              <Link exact to="/ivf_treatment">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={dental_care} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>العناية بالأسنان</Card.Text>
              <Link exact to="/dental_care">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={gynaecologyy} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>أمراض النساء</Card.Text>
              <Link exact to="/gynaecology">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={health_care} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>فحص الصحة</Card.Text>
              <Link exact to="/health_check_up">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

      </div>




      <div className={styles.container}>
        <div className={styles.pair}>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={orthopaedic_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>جراحة العظام</Card.Text>
              <Link exact to="/orthopaedic_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={baby_delivery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>تسليم الطفل</Card.Text>
              <Link exact to="/baby_delivery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
         
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={bone_marrow_transplant} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>زرع نخاع العظام</Card.Text>
              <Link exact to="/bone_marrow_transplant">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={joint_replacement_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>استبدال مشترك</Card.Text>
              <Link exact to="/joint_replacemnet_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={neuro_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>جراحة الاعصاب</Card.Text>
              <Link exact to="/neuro_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={breast_implants} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>زراعة الثدي</Card.Text>
              <Link exact to="/breast_implants">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

      </div>



      <div className={styles.container}>
        <div className={styles.pair}>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={breast_reduction_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>جراحة تصغير الثدي</Card.Text>
              <Link exact to="/breast_reduction_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={diabetes_treatment} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>علاج مرض السكري</Card.Text>
              <Link exact to="/diabetes_treatment">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
         
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={ent_care} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>عيون أنف حلق</Card.Text>
              <Link exact to="/ent_care_india">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={eye_care} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>العناية بالعيون</Card.Text>
              <Link exact to="/eye_care">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={fat_grafts} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>ترقيع الدهون الجراحة التجميلية</Card.Text>
              <Link exact to="/fat_grafts_plastic_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={foot_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>جراحة القدم</Card.Text>
              <Link exact to="/foot_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

      </div>


      <div className={styles.container}>
        <div className={styles.pair}>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={skin_treatment} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>علاج الجلد</Card.Text>
              <Link exact to="/skin_treatment">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={hernia_operation} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>عملية فتق</Card.Text>
              <Link exact to="/hernia_operation">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
         
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={kidney_transplant} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>زرع الكلى</Card.Text>
              <Link exact to="/kidney_transplant">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={liposuction} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>زرع الكلى</Card.Text>
              <Link exact to="/liposuction">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

        <div className={styles.pair}>
        <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={liver_transplant} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>زراعة الكبد بالنقل</Card.Text>
              <Link exact to="/liver_transplant">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "10rem" }}
            className={`text-center ${styles.card}`}
          >
            <Card.Img variant="top" src={obesity_surgery} className={styles.imgage} />
            <Card.Body className={styles.cardbody}>
              <Card.Text>جراحة السمنة</Card.Text>
              <Link exact to="/obesity_surgery">
                <button>تفاصيل</button>
              </Link>
              <Link exact to="/assistance">
                <button>مساعدة</button>
              </Link>
            </Card.Body>
          </Card>
        </div>

      </div>

      </div>


    // <div className={styles.container}>
    //   <div className={styles.imgpart}>
    //     <img src={treatment} alt="treatment"></img>
    //   </div>
    //   <div className={styles.textpart}>
    //     <div className={styles.pair}>
    //     <Link exact to="/health_care">
    //       <button>See More</button>
    //     </Link>
    //     <Link exact to="/cosmetic_surgery">
    //       <button>cosmetic surgery</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/ivf_treatment">
    //       <button>ivf treatment</button>
    //     </Link>
    //     <Link exact to="/dental_care">
    //       <button>dental care</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/gynaecology">
    //       <button>gynaecology</button>
    //     </Link>
    //     <Link exact to="/health_check_up">
    //       <button>health checkup</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/orthopaedic_surgery">
    //       <button>orthopaedic surgery</button>
    //     </Link>
    //     <Link exact to="/baby_delivery">
    //       <button>baby delivery</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/bone_marrow_transplant">
    //       <button>bonemarrow transplant</button>
    //     </Link>
    //     <Link exact to="/joint_replacemnet_surgery">
    //       <button>joint replacemnet surgery</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/neuro_surgery">
    //       <button>neuro surgery</button>
    //     </Link>
    //     <Link exact to="/breast_implants">
    //       <button>breast implants</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/breast_reduction_surgery">
    //       <button>breast reduction surgery</button>
    //     </Link>
    //     <Link exact to="/diabetes_treatment">
    //       <button>diabetes treatment</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/ent_care_india">
    //       <button>ent care india</button>
    //     </Link>
    //     <Link exact to="/eye_care">
    //       <button>eye care</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/fat_grafts_plastic_surgery">
    //       <button>fat grafts plastic surgery</button>
    //     </Link>
    //     <Link exact to="/foot_surgery">
    //       <button>foot surgery</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/skin_treatment">
    //       <button>skin treatment</button>
    //     </Link>
    //     <Link exact to="/hernia_operation">
    //       <button>hernia operation</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/kidney_transplant">
    //       <button>kidney transplant</button>
    //     </Link>
    //     <Link exact to="/liposuction">
    //       <button>liposuction</button>
    //     </Link>
    //     </div>

    //     <div className={styles.pair}>
    //     <Link exact to="/liver_transplant">
    //       <button>liver transplant</button>
    //     </Link>
    //     <Link exact to="/obesity_surgery">
    //       <button>obesity surgery</button>
    //     </Link>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Treatment;
