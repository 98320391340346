import React from "react";
import styles from "./styles.module.css";

function BabyDelivery() {
  return (
    <div className={styles.container}>
      <h1>Child Birth</h1>
      <p>
        يعتبر الحمل والولادة من أكثر الأشياء شيوعًا في حياتنا. هذه شائعة وطبيعية
        مثل الأنشطة مثل تناول الطعام أو التنفس. ومع ذلك ، عندما يتوقع أي من
        أقربائنا وأحبائنا ، فإننا في معظم الوقت نشعر بالتوتر إلى حد ما
      </p>
      <p>
        لذلك ، من الطبيعي أن نختار تدابير احترازية إضافية لتأمين حالة الحمل
        والولادة. من الطبيعي جدًا أن يتم إعطاء الولادة في الهند أيضًا الكثير من
        الجدية. هناك عدد قليل من القضايا التي تشكل الأسباب الأكثر شيوعًا لوفاة
        الأمهات. وهي عبارة عن نزيف مفرط أثناء الولادة ، والتهابات خطيرة ، وما
        إلى ذلك. هناك أيضًا العديد من حالات الإجهاض حيث لا يتم اتخاذ تدابير
        السلامة الكافية. نتيجة لذلك ، يؤدي إلى وفيات الأمهات. ومن ثم فمن الحكمة
        دائمًا التعرف على الخيارات المختلفة المتاحة لولادة الأطفال في الهند قبل
        اتخاذ قرار فعلي بشأن المشكلة
      </p>
      <p>
        على الرغم من أن الحالة الصحية العامة في الهند لم تصل بعد إلى درجة يمكننا
        تصنيفها على أنها "مرضية" ، فقد شهدت السنوات القليلة الماضية قدرًا كبيرًا
        من التحسن فيما يتعلق بولادة الأطفال في الهند. جميع المستشفيات في الهند
        لديها أقسام أمومة منفصلة. يدير هذه الأقسام أطباء ومساعدون متخصصون في
        حالات الحمل ومهنيون بقدر ما يتعلق الأمر بعملية الولادة
      </p>
      <p>
        تحتوي هذه المستشفيات على أجنحة منفصلة للأمهات الحوامل يتم تزويدها بجميع
        أنواع المرافق التي قد تكون ضرورية لمواجهة أي حالة سيئة محتملة ناتجة عن
        مضاعفات الحمل. إلى جانب ذلك ، هناك أيضًا مرافق حيث يمكن توفير أفضل
        الظروف العامة لحديثي الولادة. لقد أصبح هذا معيارًا جيدًا لولادة الأطفال
        في الهند
      </p>
    </div>
  );
}

export default BabyDelivery;
