import React from "react";
import styles from "./styles.module.css";

function SkinTreatment() {
  return (
    <div className={styles.container}>
      <h1>Skin Treatment</h1>
      <p>
        الهند لديها تاريخ غني جدا. ربما يكون أحد أكبر الدعاة لهذا هو علاج
        الايورفيدا. كانت الأيورفيدا مخزنًا للعلاجات الطبيعية للبشرة. بمساعدة
        إجراء العلاج هذا ، يمكنك التخلص من جميع الأمراض الجلدية. ومع ذلك ، مع
        مرور الوقت وطرق جديدة وأفضل لعلاج مشاكل الجلد ، انخفضت شعبية هذه
        العلاجات ببطء
      </p>
      <p>
        ومع ذلك ، هذا لا يعني أن شعبية علاج الجلد في الهند قد انخفضت بأي شكل من
        الأشكال. في الواقع ، أصبحت معالجة الجلد في الهند أكثر شعبية من أي وقت
        مضى. الأسباب كثيرة ويمكن تلخيصها فيما يلي:
      </p>
      <p>
        كفاءة أطباء الأمراض الجلدية-: السيناريو الطبي للبلد يرأسه مجموعة من
        أطباء الأمراض الجلدية المشهورين. يمتلك أخصائيو الجلد هؤلاء المعرفة
        والخبرة لرعاية أكبر مشاكل الجلد. ما هو مشجع هو أن المزيد والمزيد من
        أطباء الجلد الواعدين يأتون كل يوم. والنتيجة هي أن هؤلاء الأطباء قد
        استحوذوا على كريم طبيب الأمراض الجلدية الدولي. لذا فإن الحصول على علاج
        لبشرتك في بلد مزدهر من أطباء الجلد ليس فكرة سيئة
      </p>
      <p>
        التكلفة المنخفضة للعلاج - التكلفة المنخفضة للعلاج هي العبارة الأساسية
        التي جعلت علاج الجلد الهند على ما هو عليه اليوم. معظم المتخصصين في الجلد
        في العالم يتقاضون مبالغ سخيفة كرسوم. لكي تكون أكثر تحديدًا ، يمكن أن
        تؤدي تكلفة علاج الجلد في أوروبا وأمريكا إلى انشقاقات. ومع ذلك ، فإنه لا
        يتوقف هنا فقط. أضف إلى ذلك أن تكلفة الدواء تزيد الأمر سوءًا. ومع ذلك ،
        فإن علاج الجلد في الهند يمكن أن يوفر لك الكثير من المال حيث أن تكلفة
        العلاج ليست عالية ، ولا يستخرج الأطباء الكثير من المال من مرضاهم. ومع
        ذلك ، فإن ما فعل الحيلة حقًا لعلاج الجلد في الهند هو تدخل الشركات في
        ضمان رحلة سهلة وآمنة للمرضى الدوليين
      </p>
    </div>
  );
}

export default SkinTreatment;
