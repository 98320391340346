import React from "react";
import styles from "./styles.module.css";

function BreastImplants() {
  return (
    <div className={styles.container}>
      <h1>Breast Implants</h1>
      <p>
        مع تحول الهند إلى مركز عالمي للسياحة الطبية ، يمكن الاستفادة من جميع
        أنواع العلاجات الحديثة بسهولة في هذا البلد. ولا تقتصر هذه المرافق
        المتوفرة في الهند على المشكلات الصحية العادية والأساسية مثل أمراض القلب
        أو أي مشكلات أخرى من هذا القبيل
      </p>
      <p>
        لا يمكن للمرء أن يقرر فجأة إجراء زراعة ثدي في الهند ويأمل أن يتم إجراؤه
        في غضون فترة زمنية قصيرة جدًا ، على الرغم من أن الفترة الزمنية المطلوبة
        للإجراء نفسه تتراوح بين يوم إلى ثلاثة أيام فقط. الوقت الإضافي مطلوب في
        الواقع لاستكمال جميع الإجراءات التي تعتبر ضرورية للغاية لأي امرأة تريد
        إجراء عمليات زراعة الثدي في الهند
      </p>
      <p>
        في البداية ، سيتعين عليها الاتصال بالوكيل المناسب الذي يمكنه بدوره
        الاتصال بممثلين طبيين من الهند. سيتعين عليها ملء جميع النماذج المطلوبة
        التي سيطلب منها الوكيل تحديد احتياجاتها الفعلية ومتطلباتها التي توفر
        جميع المواصفات
      </p>
      <p>
        بعد ذلك سيتعين عليها السفر إلى الهند ومقابلة الممثلين الطبيين المطلوبين
        الذين سيساعدونها في إجراء عمليات زراعة الثدي في الهند دون أي متاعب. سوف
        يرتبون للطبيب الذي سيجري العملية الجراحية الفعلية. وحتى بعد أن تتعافى
        وتعود سيتواصلون معك للاستعلام عن حالتك الصحية. وكل هذه العناية الخاصة
        التي يقدمونها تجعل تجربة الحصول على العلاج من قبلهم كاملة ومرضية
      </p>
    </div>
  );
}

export default BreastImplants;
