import React from "react";
import styles from "./styles.module.css";

function LiverTransplant() {
  return (
    <div className={styles.container}>
      <h1>Liver Transplant</h1>
      <p>
        يشار إلى زراعة الكبد أيضًا باسم زراعة الكبد. تتضمن هذه الجراحة الكبرى
        استبدال الكبد المصاب بطعم كبد صحي. التقنية الأكثر شيوعًا التي يستخدمها
        الجراحون لإجراء عملية زرع الكبد في الهند هي زرع الأعضاء التناسلية
      </p>
      <p>
        تتضمن هذه التقنية إزالة الكبد الأصلي ووضع العضو المتبرع في نفس الموقع
        التشريحي مثل الكبد الأصلي. يعتبر زرع الكبد في الهند خيارًا شائعًا
        ومقبولًا جيدًا لعلاج أمراض الكبد في المرحلة النهائية وحالات الفشل الكبدي
        الحادة
      </p>
      <p>
        زراعة الكبد في الهند علاج مقبول يتم إجراؤه في حالات فشل الكبد أو أمراض
        الكبد في المرحلة النهائية. على الرغم من أنه في بعض حالات الفشل الكبدي ،
        يمكن للمرضى التعافي من خلال العلاج في وحدات العناية المركزة للكبد
        المتخصصة ، ولكن في معظم الحالات يكون زرع الكبد هو الخيار الوحيد للبقاء
        على قيد الحياة
      </p>
      <p>
        هناك العديد من الجراحين الهنود ، الذين يعملون مع بعض المستشفيات الهندية
        الرائدة ، وهم خبراء في زراعة الأعضاء وتم تدريبهم وعملهم لعدة سنوات في
        أكثر المراكز الصحية شهرة في العالم ، مثل الولايات المتحدة الأمريكية
        والمملكة المتحدة واليابان. يتم إجراء زراعة الكبد في الهند بمعدلات نجاح
        عالية ، من قبل العديد من المراكز الصحية
      </p>
      <p>
        الأهم من ذلك ، يمكن إجراء عملية زرع الكبد بكفاءة في الهند بتكلفة تقارب
        نصف تكلفة الدول الغربية. مستشفى أبولو في تشيناي ، ومستشفى جاسلوك في
        مومباي ، ومستشفى سانت جونز في بنغالور هي أسماء بعض المستشفيات التي تجري
        عمليات زراعة الكبد في الهند. علاوة على ذلك ، بدأت الحكومة الهندية مؤخرًا
        في إصدار تأشيرة طبية لأولئك الذين يزورون البلاد لتلقي العلاج الطبي ، مما
        يسهل على المرضى من الخارج.
      </p>
    </div>
  );
}

export default LiverTransplant;
