import React from "react";
import styles from "./styles.module.css";

function CosmeticSurgery() {
  return (
    <div className={styles.container}>
      <h1>Cosmetic surgery</h1>
      <p>
        إن الأفق الجديد للمجال الطبي الذي يظهر في الهند هو الجراحة التجميلية
        التي تستخدم بعض التقنيات الحديثة في الإجراءات التصحيحية. تشمل بعض
        التشوهات التي تم تصحيحها استعادة الشعر (زراعة الشعر ، وقلائد الشعر ،
        وتقليص فروة الرأس) ، وعمليات تجميل الأنف (إعادة تشكيل أو إعادة تجول
        الأنف) ، وتوقف عملية الشيخوخة (حياة الوجه ، وجراحة تجميل الجفن ، ورفع
        الحاجب ، واستئصال الدهون شبه المعدنية من أجل الذقن المزدوجة) ، إزالة
        السنفرة (صنفرة الوجه) ، تجميل الأذن للأذن البارزة ، تكبير الذقن والخد ،
        تصغير الشفاه ، أنواع مختلفة من جراحة الثدي وإعادة الترميم وشفط الدهون.
        لكي نكون صادقين مع الأحداث المنتشرة في كل مكان ، علينا أن نعترف بأن
        الهند لم تعد نقطة جذب تاريخية ولكنها برزت كواحدة من أكثر الحلول
        التنافسية والحديثة القائمة على التكنولوجيا لجميع المشاكل الطبية
      </p>
      <p>
        حزمة الجراحة التجميلية هي واحدة من السحر الذي أظهرته حزم العلاج الطبي
        الهندي. في المقام الأول ، يختار مجتمع النخبة هذا لأنه ميسور التكلفة
        بالنسبة لهم. أضاف استخدام النظائر المشعة بجرعات صغيرة واستخدام كاميرا
        جاما في حزم العلاج بُعدًا جديدًا للسياحة العلاجية في الهند. يأتي الأجانب
        كل عام إلى الهند لإجراء فحوصات طبية بسعر معقول
      </p>
      <p>
        تتم ممارسة الأحداث الجراحية غير الغازية مثل الجراحة الإشعاعية بالتوضيع
        التجسيمي والعلاج الإشعاعي لأورام الدماغ بشكل مثمر. السياحة الطبية هي
        مفهوم جديد وناشئ على المستوى الدولي وأحد العمليات الجراحية الرئيسية التي
        يختار المرضى من أجلها السياحة الطبية هي الجراحة التجميلية ، وما هو
        المكان الأفضل للقيام بذلك من الهند. تشمل المزايا التكلفة المنخفضة وعدم
        وجود قوائم انتظار وعطلة عالية المستوى. أعلن أحدث تقرير صادر عن
        "الإندبندنت" أن أكثر من عشرة آلاف بريطاني يسافرون إلى الهند كل عام من
        أجل شد البطن وتضخيم الثدي وعمليات شد الوجه مع قضاء عطلة نقاهة على
        الشاطئ.
      </p>
    </div>
  );
}

export default CosmeticSurgery;
