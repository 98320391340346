import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import styles from "./Testimonial.module.css";

import pic1 from "../images/testimonialpic1.PNG";
import pic2 from "../images/testimonialpic2.PNG";
import pic3 from "../images/testimonialpic3.PNG";

export default function Testimonial() {
  return (
    <div className={styles.container}>
      <Carousel variant="dark">
        <Carousel.Item>
          <div className={styles.slide}>
            <img src={pic1}></img>
            <p>
              لقد منحتنا إحسان ميدياكل للسياحة خدمات عالية المستوى في الهند. مجد
              لهم. إن استجابتهم السريعة وقدرتهم على حل المشكلات لتهدئة فضولنا
              أمر يستحق الثناء. لقد كنا محظوظين لأننا كنا تحت رعايتهم الجيدة
              ولذا نسأل الله أن يثريهم بأفضل ما في حياتهم. آمين!
            </p>

            <h1>مر عبدلله المشرقي (سلطنت أف عمان)</h1>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className={styles.slide}>
            <img src={pic2}></img>
            <p>
              لحظة وصولي إلى الهند ، شعرت بالأمان التام في أيدي شركة إحسان
              للسياحة العلاجية. تم اتخاذ خطوات فورية لاستعادة مشكلتي. نحن سوف
              مجهزة بمرافق ممتازة تشمل الراحة والنظافة وطاقم تمريض كفؤ ، تركت
              إحسان للسياحة الطبية انطباعًا إيجابيًا للغاية عن المرافق الطبية
              هنا في الهند.
            </p>

            <h1>السيدة. رشيدة (جمهورية تنزانيا المتحدة) د.مريم (العراق)</h1>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={styles.slide}>
            <img src={pic3}></img>
            <p>
              لقد كانت الرعاية والمساعدة اللطيفة التي قدمها طاقم المستشفى رائعة
              ، فقد كان الموظفون سريعون ودقيقون في تقديم خطاب لمشكلتي في الوقت
              المناسب. أنا سعيد جدًا بالسرعة التي تم بها حل الأمور من قبل فريق
              السياحة العلاجية بإحسان.
            </p>

            <h1>السيد. سعيد كوروزوفا (سمرقند ، أوزباكستان)</h1>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
