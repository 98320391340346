import React from "react";
import { Link } from "react-router-dom";
import styles from "./Hospitals.module.css";
import pic from "../images/CtScan.png";
import hospital from "../images/hospital.png";
import treatment from "../images/treatment.png";

function Hospitals() {
  return (
    <div className={styles.container}>
      <div className={styles.packet}>
        <div className={styles.img}>
          <img src={hospital} alt="hospital"></img>
        </div>
        <div className={styles.text}>
          <h1>المستشفيات</h1>
          <p>
            تقدم الهند مجموعة عالمية من المستشفيات ذات جودة عالية من العلاج ،
            انقر أدناه لرؤية القائمة الكاملة للمستشفيات التي تقدمها الهند
          </p>
          <Link exact to="/hospital">
            <button>راجع المستشفيات</button>
          </Link>
        </div>
      </div>

      <div className={styles.packet}>
        <div className={styles.text}>
          <h1>المستشفيات</h1>
          <p>
            نغطي أيضًا مجموعة كبيرة ومتنوعة من العلاجات سواء كانت إجراءات
            تجميلية صغيرة أو عمليات جراحية معقدة ، انقر أدناه للاطلاع على
            القائمة الكاملة للعلاجات المتاحة
          </p>
          <Link exact to="/treatment">
            <button>انظر العلاجات</button>
          </Link>
        </div>
        <div className={styles.img}>
          <img src={treatment} alt="treatment"></img>
        </div>
      </div>
    </div>
  );
}

export default Hospitals;
