import React from "react";
import Card from "react-bootstrap/Card";
import styles from "./Hosp.module.css";
import { Link } from "react-router-dom";

import artemis from "./hospital_images/artemis.jpg";
import alchemist from "./hospital_images/alchemist.png";
import ambani from "./hospital_images/ambani.jpg";
import apollo from "./hospital_images/apollo.jpg";
import asian from "./hospital_images/asianheartinstitute.jpg";
import batra from "./hospital_images/batra.png";
import bensups from "./hospital_images/bensups.jpg";
import centreforsight from "./hospital_images/centreforsight.png";
import columbia from "./hospital_images/columbiaasia.png";
import fortis from "./hospital_images/fortis.jpg";
import fortisescorts from "./hospital_images/fortisescorts.png";
import lilavati from "./hospital_images/lilavati.jpg";
import max from "./hospital_images/max.png";
import medanta from "./hospital_images/medanta.png";
import metro from "./hospital_images/metro.jpg";
import moolchand from "./hospital_images/moolchand.jpg";
import paras from "./hospital_images/paras.jpg";
import primus from "./hospital_images/primus.png";
import psri from "./hospital_images/psri.png";
import rajiv from "./hospital_images/rajivgandhi.png";
import rockland from "./hospital_images/rockland.PNG";
import sevenhills from "./hospital_images/sevenhills.png";
import tata from "./hospital_images/tata.PNG";
import wockhardt from "./hospital_images/wockhardt.png";
import { nativeTouchData } from "react-dom/cjs/react-dom-test-utils.development";

function Hosp() {
  return (
    <div className={styles.group}>
      <div className={styles.container}>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={artemis} className={styles.img} />
          <Card.Body>
            <Card.Text>
              مستشفيات أرتميس القطاع 51 ، جورجرام 122001 هاريانا ، الهند
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={alchemist} className={styles.img} />
          <Card.Body>
            <Card.Text>
              طريق ملعب الجولف ، بارسفناث إكزوتيكا ، دي إل إف المرحلة 5 ، القطاع
              53 ، جورجاون ، هاريانا 122002
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={ambani} className={styles.img} />
          <Card.Body>
            <Card.Text>
              راو صاحب أشوتراو باتوردهان مارج ، أربعة أكواخ ، أنديري مومباي
              400053
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={apollo} className={styles.img} />
          <Card.Body>
            <Card.Text>
              مستشفى إندرابراثا أبولو ، ساريتا فيهار ، طريق دلهي ماثورا
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
      </div>

      {/* ---------------*/}
      <div className={styles.container}>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={asian} className={styles.img} />
          <Card.Body>
            <Card.Text>
              المعهد الآسيوي للعلوم الطبية ، قطاع 21-أ ، فريد آباد
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={batra} className={styles.img} />
          <Card.Body>
            <Card.Text>
              منطقة طغلك آباد المؤسسية ، طريق مهرولي باداربور ، جنوب دلهي -
              110062
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={bensups} className={styles.img} />
          <Card.Body>
            <Card.Text>
              قطاع 12 ، قطاع الدواركا 12 دواركا ، نيو دلهي 110075
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={centreforsight} className={styles.img} />
          <Card.Body>
            <Card.Text>جيب سافدارجونج ، نيودلهي 110029</Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
      </div>

      {/* ---------------*/}
      <div className={styles.container}>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={columbia} className={styles.img} />
          <Card.Body>
            <Card.Text>
              بلوك ف ، جول شكار بالام فيهار ، قطاع 23 ، جورجاون 122001
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={fortis} className={styles.img} />
          <Card.Body>
            <Card.Text>
              معهد فورتيس للبحوث التذكارية ، القطاع - 44 ، مقابل هدى سيتي سنتر
              جورجاون ، هاريانا - 122002
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={fortisescorts} className={styles.img} />
          <Card.Body>
            <Card.Text>
              طريق أخلا ، محطة مترو سوخدف فيهار ، نيودلهي ، دلهي 110025
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={lilavati} className={styles.img} />
          <Card.Body>
            <Card.Text>
            أ 791 باندرا استصلاح ، مومباي ماهاراسترا 400050
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
      </div>

      {/* ---------------*/}
      <div className={styles.container}>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={max} className={styles.img} />
          <Card.Body>
            <Card.Text>
              اضغط على مارج الجيب ، منطقة المؤسسية ، ساكت ، نيودلهي ، دلهي 110017
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={medanta} className={styles.img} />
          <Card.Body>
            <Card.Text>
            ميديسيتي ، مستعمرة إسلامبور ، القطاع 38 ، جوروجرام ، هاريانا 122001
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={metro} className={styles.img} />
          <Card.Body>
            <Card.Text>X 1 ، القطاع 12 ، نويدا أوتار براديش 201301</Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={moolchand} className={styles.img} />
          <Card.Body>
            <Card.Text>
              Lلاجبات ناجار الثالث ، لاجبات ناجار ، نيودلهي ، دلهي 110024
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
      </div>

      {/* ---------------*/}
      <div className={styles.container}>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={paras} className={styles.img} />
          <Card.Body>
            <Card.Text>
              Sushant Lok- 1, Sector-43, Phase- I, Gurugram, Haryana 122002
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={primus} className={styles.img} />
          <Card.Body>
            <Card.Text>
              Chandragupt marg , chanakyapuri , new delhi 110021
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={psri} className={styles.img} />
          <Card.Body>
            <Card.Text>
              Press Enclave marg , chanakyapuri new delhi 110017
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={rajiv} className={styles.img} />
          <Card.Body>
            <Card.Text>
              rajiv gandhi cancer Institute , sector 5 , rohini delhi 110085
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
      </div>

      {/* ---------------*/}
      <div className={styles.container}>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={rockland} className={styles.img} />
          <Card.Body>
            <Card.Text>
              B-33 and34, Qutab Institutional Area Katwaria Sarai, South West,
              Delhi - 110016
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={sevenhills} className={styles.img} />
          <Card.Body>
            <Card.Text>
              Marol maroshi road , andra East , maharastra 400059
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={tata} className={styles.img} />
          <Card.Body>
            <Card.Text>Dr E borges parel , mumbai 400012</Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
        <Card
          style={{ width: "15rem" }}
          className={`text-center ${styles.card}`}
        >
          <Card.Img variant="top" src={wockhardt} className={styles.img} />
          <Card.Body>
            <Card.Text>
              wockhardt towers , bandra kurla complex , bandra east , mumbai
              400051
            </Card.Text>
            <Link exact to="/assistance">
              <button>مساعدة</button>
            </Link>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Hosp;
