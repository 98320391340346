import React from "react";
import styles from "./styles.module.css";

function Gynaecology() {
  return (
    <div className={styles.container}>
      <h1>Gynaecology</h1>
      <p>
        المستشفيات الهندية الرائدة مع أقسام أمراض النساء والمستشفيات النسائية
        لديها مرافق للوقاية والكشف المبكر عن الاضطرابات النسائية. يوجد في العديد
        من المستشفيات برامج فحص النساء المصممة للكشف عن العلامات المبكرة
        لاضطرابات الثدي وأعضاء الإنجاب وكذلك تلبية احتياجات وسائل منع الحمل
        للمرأة
      </p>
      <p>
        يعد التصوير الشعاعي للثدي والموجات فوق الصوتية للحوض ومسحة عنق الرحم
        جزءًا لا يتجزأ من أي فحص طبي جيد للنساء. تتوفر رعاية طبية وجراحية متخصصة
        لجميع أنواع مشاكل أمراض النساء مثل تشوهات الدورة الشهرية والهبوط
        والأورام الليفية وأورام الرحم والمبايض الأخرى وإعادة استقناء البوق عن
        طريق الجراحة المجهرية ورعاية الزوجين المصابين بالعقم. تتوفر جراحة أمراض
        النساء على أحدث طراز بمعدات وخبرات عالمية باستخدام تقنيات الحد الأدنى من
        التدخل الجراحي
      </p>
      <p>
        يمكن إجراء حالات الحمل خارج الرحم ، والتكيسات والأورام المفرطة ، والتهاب
        بطانة الرحم الليفي ، والكتل البوقية وحتى استئصال الرحم بالمنظار. تمتلك
        مستشفيات مثل أبولو مختبرات أطفال الأنابيب على أحدث طراز مدعومة بأطباء
        ذوي خبرة عالية شاركوا في مجال العقم وتقنيات الإنجاب المساعدة
      </p>
      <p>
        تتوفر مجموعة كاملة من الإجراءات التشخيصية للأزواج المصابين بالعقم. علاج
        اضطرابات الإباضة ، التحفيز المبيض الخاضع للرقابة ، تحفيز المبيض الخاضع
        للرقابة ، التلقيح داخل الرحم ، التلقيح البوقي داخل فالوب ، الإخصاب في
        المختبر ونقل الجاميت داخل فالوب ، نقل الأجنة ، نقل الزيجوت داخل فالوب ،
        التبرع بالحيوانات المنوية ، تجميد البويضات / التبرع بالبويضات ، واستبدال
        الأجنة المجمدة ، ومجموعة كاملة من علاج العقم عند الذكور بما في ذلك تقنية
        المعالجة الدقيقة - حقن الحيوانات المنوية داخل السيتوبلازم (الحقن
        المجهري) وتأجير الأرحام
      </p>
    </div>
  );
}

export default Gynaecology;
