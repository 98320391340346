import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import insta from "./pages/images/instagram.png";
import map from "./pages/images/map.png";

function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.part}>
        <ul>
          <Link exact to="/whyus">
            <li>لماذا نحن</li>
          </Link>
          <Link exact to="/hospital">
            <li>المستشفيات</li>
          </Link>
          <Link exact to="/treatment">
            <li>علاج او معاملة</li>
          </Link>
        </ul>
      </div>
      <div className={styles.part}>
        <ul>
          <Link exact to="/contactus">
            <li>اتصل بنا</li>
          </Link>
          <Link exact to="/assistance">
            <li>مساعدة</li>
          </Link>
          <li>الاعتمادات</li>
        </ul>
      </div>
      <div className={styles.part}>
        <p>
          سلطنة عمان، ولاية السيب، الحيل الجنوبية، بناية الإيمان الدور الرابع،
          بجنب مطعم انطاليا، قرب الخميس للأحذية
        </p>
      </div>
      <div className={styles.part}>
        <div>
          <a href="https://www.instagram.com/ihsanmed2016/" target="_blank">
            <img src={insta}></img>
          </a>
          <a href="https://www.google.com/maps/place/%D8%AF%D8%B1%D8%A9+%D8%A7%D9%84%D8%A5%D8%AD%D8%B3%D8%A7%D9%86+%D9%84%D9%84%D8%AA%D9%86%D8%B3%D9%8A%D9%82+%D8%A7%D9%84%D8%B7%D8%A8%D9%8A%E2%80%AD/@23.6274131,58.2351023,18.08z/data=!4m5!3m4!1s0x0:0x7df18fdceb363026!8m2!3d23.6269073!4d58.235084" target="_blank">
            <img src={map}></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
