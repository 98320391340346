import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import finallogo from "./pages/images/Finallogo.svg";
import logowithouttext from "./pages/images/logowithouttext.png";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="mynavbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo logowithouttext">
            <img src={logowithouttext} ></img>
          </NavLink>
          {/* <NavLink exact to="/" className="nav-logo logowithtext">
            <img src={logowithouttext} ></img>
          </NavLink> */}

          <NavLink exact to="/assistance" className="btn">
            <button>احصل على المساعدة</button>
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                الصفحة الرئيسية
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/whyus"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                لماذا نحن
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/hospital"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                المستشفيات
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/treatment"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                علاج او معاملة
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contactus"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                اتصل بنا
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
