import React from "react";
import styles from "./styles.module.css";

function HealthCare() {
  return (
    <div className={styles.container}>
      <h1>heart Care</h1>
      <p>
        أصبح القلق بشأن أمراض القلب تخصصًا في الهند ، حيث أصبحت أسماء مثل معهد
        كل الهند للعلوم الطبية ومستشفى أبولو أسماء يجب مراعاتها. إنهم يخلطون بين
        أحدث الابتكارات في مجال الإلكترونيات الطبية مع إتقان لا مثيل له في كبار
        أطباء القلب وجراحي القلب وجراحي القلب
      </p>
      <p>
        تتميز هذه المراكز بخصائص توفير رعاية قلبية واسعة تمتد من الخدمات
        الأساسية في طب القلب الوقائي إلى تقنيات الشفاء الأكثر تعقيدًا.
        التكنولوجيا حديثة وعالمية المستوى والكميات التي تم التعامل معها تطابق
        المعايير الدولية. كما أنهم متخصصون في تقديم الجراحة للمرضى المعرضين
        لمخاطر عالية مع مقدمة من التقنيات المبتكرة لحزمة جراحة القلب الروبوتية
        والخبيثة بوضوح الهند. مراكز القلب الرئيسية مثل معهد إسكورتس للقلب لديها
        وحدات رعاية القلب مع المعدات الحضرية ومرافق التحقيق مثل تخطيط صدى القلب
        مع دوبلر ملون ، نووي المسح وتصوير الأوعية التاجية هي بعض المستشفيات في
        الهند المخصصة تمامًا لحزمة علاج القلب في الهند. تتضمن باقة العناية
        بالقلب في الهند ما يلي:
      </p>
      <ul>
        <li>الإجهاد / يستريح الثاليوم</li>
        <li>مقياس الكالسيوم في القلب</li>
        <li>تصوير الأوعية التاجية بالرنين المغناطيسي</li>
        <li>تصوير البطينين النووي الراديوي متعدد البوابات</li>
        <li>طب القلب التدخلي بكاميرا جاما المزدوجة الرأس</li>
        <li>تصوير الأوعية التاجية والشريان السباتي والكلى والمحيطي</li>
        <li>استئصال الشرايين الدوراني والموجه</li>
      </ul>
    </div>
  );
}

export default HealthCare;
