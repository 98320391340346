import React from "react";
import styles from "./styles.module.css";

function DentalCare() {
  return (
    <div className={styles.container}>
      <h1>Dental Care</h1>
      <p>
        رعاية الأسنان في الهند هي مفهوم جديد وقادم لقضاء عطلة مخططة جنبًا إلى
        جنب مع حل ورعاية الأسنان الكاملة
      </p>
      <p>
        مشاكل الرعاية الصحية وحزم العلاج باهظة الثمن في معظم الدول الأوروبية
        والأمريكية مقارنة بهذا الجزء من العالم. تقدم الهند حزمة طبية متميزة
        للعناية بالأسنان لتلبية نفقات العلاج الخاصة بك وفي نفس الوقت ترشدك لقضاء
        الإجازة. يمكنك الاستمتاع بإجازتك والحصول على الخدمات المرتبطة بطب
        الأسنان مثل زراعة الأسنان بسعر معقول جدًا
      </p>
      <p>
        طب الأسنان هو صناعة غزيرة مع 13000 طبيب أسنان ممارس في الولايات المتحدة.
        بينما يعمل في الهند 80000 طبيب أسنان. ومع ذلك ، في نفس الوقت لا يمكن
        تحملها من قبل المواطنين العاديين بسبب خدمات العناية بالأسنان الفاخرة.
        عادة لا يتم تعويض علاج العناية بالأسنان من قبل مخططات الصحة الوطنية في
        بعض البلدان وهو أنيق للغاية
      </p>
      <p>
        أصبحت الهند مؤخرًا نقطة محورية رئيسية للسياح في الحلول الطبية للعناية
        بالأسنان. يمكنك إصلاح أسنانك وجعل عطلتك ممتازة في نفس الوقت أو ببساطة ،
        استمتع بأشكال مختلفة من الهند واحصل على علاج الأسنان دون الحاجة إلى
        الحصول على ثقب في جيبك. على الرغم من التكلفة المنخفضة ، لا تزال تحصل على
        حزمة معالجة عالية الجودة في الهند ، ويرجع ذلك أساسًا إلى انخفاض تكلفة
        العمالة مقارنة بأي منطقة أخرى في العالم. لأن المدخرات مهمة فقط إذا تلقى
        المريض علاجًا جيدًا ، يعادل أو أفضل من العلاج الذي يمكن الوصول إليه في
        بلده الأصلي
      </p>
      <ul>
        <li>
        طب الأسنان التجميلي والترميمي. (حشوات الأسنان الملونة والقشور)
        </li>
        <li>
        التاج والجسر للاستبدال الثابت للأسنان المكسورة والمفقودة
        </li>
        <li>معالجة قناة الجذر</li>
        <li>زرع الأسنان</li>
        <li>إجراءات تبييض الأسنان</li>
        <li>إجراءات جراحة الأسنان</li>
        <li>العلاج بالليزر العلاجي</li>
      </ul>
    </div>
  );
}

export default DentalCare;
