import React from "react";
import styles from "./styles.module.css";

function IvfTreatment() {
  return (
    <div className={styles.container}>
      <h1>In Vitro Fertilisation</h1>
      <p>المراحل المختلفة لعملية التلقيح الصناعي هي كما يلي</p>
      <h1>من يحتاج إلى أطفال الأنابيب</h1>
      <ul>
        <li>تلف قناة فالوب أو انسدادها</li>
        <li>
          عدد الحيوانات المنوية الغائب أو المنخفض (أقل من خمسة ملايين / مل)
        </li>
        <li>بطانة الرحم التالفة (بطانة الرحم)</li>
        <li>ضعف قدرة المبيض (البيض)</li>
        <li>فشل علاج الخصوبة لأكثر من ثلاث سنوات</li>
      </ul>
      <h1>ماذا يحدث في دورة علاج أطفال الأنابيب؟</h1>
      <p>
        عادةً ما تتكون دورة التلقيح الاصطناعي من تحفيز المبيض ، وجمع البويضات ،
        والإخصاب ، وزراعة الأجنة ، ونقل الأجنة. يتكون تحفيز المبيض من الحقن
        اليومي للهرمونات للشريك الأنثوي مع المراقبة السريرية والمراقبة بالموجات
        فوق الصوتية بشكل متكرر. يستمر هذا عادة لمدة 10-15 يومًا. يتم سحب
        البويضات عن طريق التخدير الداخلي. لا توجد شقوق أو ندبات. يتم خلط البويضة
        والحيوانات المنوية (التلقيح الاصطناعي) (الشكل 1) أو يتم حقن الحيوانات
        المنوية في البويضة (حقن الحيوانات المنوية داخل الهيولى بالحقن المجهري)
        (الشكل 2). تشكل البويضة الملقحة الجنين. يُزرع الجنين في المختبر لمدة 2-3
        أيام (الشكل 3). يتم اختيار أفضل ثلاثة أجنة ونقلها إلى الرحم في اليوم
        الثاني أو الثالث. يتم إجراء اختبار الحمل بعد 14 يومًا من يوم نقل الجنين.
      </p>
      <h1>ما هو معدل نجاح هذه العملية؟</h1>
      <p>
        يبدو كل هذا منطقيًا ونهائيًا لدرجة أن المرء يتوقع النجاح في كل حالة! لكن
        للأسف ليس هذا هو الواقع. يجب على المرء أن يفهم أنه في عملية التلقيح
        الاصطناعي بأكملها ، تعتبر كل خطوة عقبة يجب التفاوض عليها بنجاح. على سبيل
        المثال ، لا تستجيب جميع النساء لتحفيز المبيض ، ولا تنضج كل البويضات ،
        ولا يتم تخصيب كل البويضات لتكوين أجنة جيدة وليس كل الأجنة المزروعة. لذلك
        ، هناك استنزاف أو انقطاع في كل مرحلة. يوجد مجال كبير لمزيد من البحث في
        مرحلة الغرس. الغرس هو العملية التي يقبل فيها الرحم الجنين الذي يتطور بعد
        ذلك ليشكل الطفل. لقد تم إنجاز الكثير من العمل في هذا الجانب من التلقيح
        الاصطناعي ويتم محاولة الأدوية مثل الستيرويدات والأسبرين والهيبارين
        والسيلدينافيل لتحسين معدلات الزرع. يبلغ معدل نجاح التلقيح الاصطناعي
        الإجمالي حوالي 40٪ لكل دورة علاج. بالترتيب الكلمات 4 من 10 ، الذين تخلى
        عن كل آمالهم في الأبوة والأمومة يمكن أن يتصور الطفل بعد محاولة التلقيح
        الاصطناعي. معدل النجاح على مستوى العالم هو الأعلى لدى النساء الأصغر سنًا
        حيث يمكن أن يصل إلى 60٪ لكل دورة علاج. في حالة الفشل ، يمكن تجربة أطفال
        الأنابيب مرة أخرى وقد يصل معدل الحمل التراكمي على مدى 3 دورات إلى 80٪.
      </p>
    </div>
  );
}

export default IvfTreatment;
