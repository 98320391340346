import React from "react";
import styles from "./styles.module.css";

function EyeCare() {
  return (
    <div className={styles.container}>
      <h1>Eye Care</h1>
      <p>
        تعتبر العين من أهم أعضاء الجسم. إنه المصدر الوحيد للتجربة البصرية. أي
        نوع من الإهمال يمكن أن يكون ضارًا للغاية لهذا العضو الحيوي. لذلك كلما
        وجدت مشاكل في الرؤية ، يجب أن تسرع إلى أخصائي العيون دون مزيد من
        التأخير. ولكن هناك شيء واحد قد يوقفك وهو تكلفة علاج العيون. حزين ، لكنه
        حقيقي جدا. أي طبيب عيون في أوروبا أو أمريكا سيتقاضى مبلغًا ضخمًا
        كفاتورته. أضف إلى ذلك تكلفة العلاج أو العمليات الجراحية (إذا لزم الأمر).
        على الجانب الآخر من العالم ، يمكن لـ الهند العناية بالعيون أن تفعل الشيء
        نفسه بتكاليف منخفضة بشكل ملحوظ
      </p>
      <h2>ما هي علاجات العناية بالعيون المتوفرة هنا؟</h2>
      <ul>
        <li>علاج الليزك وجراحة العيون</li>
        <li>جراحة الليزر الانكسارية</li>
        <li>تصحيح العين الانكساري</li>
        <li>العلاج الطبيعي للعناية بالعيون والقائمة تطول للتو</li>
      </ul>
      <p>
        يحكم الأطباء وأطباء العيون الهنود المشهد الطبي لبعض الوقت الآن ويمكنهم
        فعل ذلك لسبب ما. نعم ، هؤلاء الأطباء لديهم كل الدرجات والمعرفة والخبرة
        التي تجعل العناية بالعيون الهند أفضل من الأفضل
      </p>
    </div>
  );
}

export default EyeCare;
