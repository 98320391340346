import React from "react";
import styles from "./styles.module.css";

function FootSurgery() {
  return (
    <div className={styles.container}>
      <h1>Foot surgery</h1>
      <p>
        التقدم التكنولوجي في جميع المجالات ، وخاصة المجال الطبي يحدث بمعدل سريع
        للغاية في الهند. نتيجة لذلك ، يتم إجراء العديد من العمليات الجراحية
        والإجراءات الطبية التي لم تكن ممكنة في السابق في الهند بانتظام مدهش في
        البلاد. يمكن أن تؤخذ جراحة القدم في الهند كمثال على هذا الاتجاه الذي تم
        ذكره أعلاه للتو.
      </p>
      <p>
        أصبح هذا التغيير المفاجئ والمذهل ممكنًا من خلال التطوير والتحسين
        المتسارعين في البنية التحتية في الهند. أصبحت التنمية متعددة الأوجه والتي
        كانت ضرورية للغاية لأنه لو تم تحسين الرعاية الصحية فقط ، فلن يخدم أي غرض
        مع تأخر جميع وسائل الراحة الأخرى
      </p>
      <p>
        عادة ما يتم إجراء جراحات القدم لتصحيح بعض التشوهات الجسدية التي تكون في
        معظم الحالات وراثية بطبيعتها وعادة ما تكون موجودة منذ ولادة المريض
        لأصابع المطرقة وتقصير أصابع القدم وهما من الأمراض لتصحيح أي جراحة للقدم
        تتم في الهند. في حالة تشوه شكل أصابع القدم السابق ، بينما في حالة إصبع
        القدم الأخير يكون أطول من الآخر مما يؤدي إلى تكوين مسامير مؤلمة على
        القدمين. الورم هو تشوه شائع آخر للقدم يوجد غالبًا عند النساء. الورم هو
        مثال على تشوه القدم الوراثي
      </p>
      <p>
        يتم إجراء جراحة القدم في الهند والتي يمكن أن تعالج جميع التشوهات
        المذكورة أعلاه بالإضافة إلى العديد من التشوهات الأخرى التي لم يتم ذكرها
        هنا. ولكن قد يتساءل المرء لماذا قد يختار الأشخاص من البلدان المتقدمة ،
        التي لديها ممارسون طبيون ذوو جودة عالية بالإضافة إلى بنية تحتية عالمية
        المستوى ، عدم رغبتهم في إجراء جراحة القدم في الهند
      </p>
    </div>
  );
}

export default FootSurgery;
