import React from "react";
import styles from "./styles.module.css";

function JointReplacementSurgery() {
  return (
    <div className={styles.container}>
      <h1>Joint Replacement Surgery</h1>
      <p>
        يتم إجراء جراحة استبدال الكتف / الورك والاستبدال الثنائي للركبة باستخدام
        جراحة ثقب المفتاح أو التنظير الداخلي وتنظير المفاصل الأكثر تقدمًا في
        العديد من المستشفيات في الهند بما في ذلك مستشفى أبولو ومستشفى سير جانجا
        رام ومستشفى العائلة المقدسة في دلهي ومستشفى بومباي ومستشفى ليلافاتي
        وهيندوجا في مومباي ومعهد مدراس لجراحة العظام وعلوم الصدمات
      </p>
      <p>
        بعض المستشفيات مثل مستشفى أبولو ماكس في دلهي لديها مسارح تشغيل مع نظام
        نظام تدفق الهواء الرقائقي الذي يقارن مع الأفضل في الولايات المتحدة
        الأمريكية والمملكة المتحدة. تبلغ تكلفة استبدال مفصل الركبة في الهند
        حوالي 3000 جنيه بينما في المملكة المتحدة ، تكلف عملية جراحية مماثلة
        باستخدام نفس الغرسات والمواد الاستهلاكية الطبية حوالي 10000 جنيه
      </p>
    </div>
  );
}

export default JointReplacementSurgery;
