import React from 'react'
import styles from "./styles.module.css";

function HealthCheckUp() {
    return (
        <div>
            
        </div>
    )
}

export default HealthCheckUp
