import React from "react";
import styles from "./styles.module.css";

function BoneMarrowTransplant() {
  return (
    <div className={styles.container}>
      <h1>Bone Marrow Transplant</h1>
      <p>
        المستشفيات الرئيسية في الهند لديها وحدات الأورام التي تضم جراحة الأورام
        والعلاج الطبي والإشعاعي بالإضافة إلى زرع نخاع العظام المهم. ساعدت وحدة
        زرع النخاع العظمي المزودة بمرشحات هيبا ذات الضغط العالي في تحقيق معدل
        نجاح مرتفع للغاية في مختلف أنواع الزرع
      </p>
      <p>
        تم إجراء عملية زرع دم الحبل السري وزراعة الخلايا الجذعية غير المتطابقة
        بنجاح ، وهو إنجاز رائع وهام ، مع الأخذ في الاعتبار حقيقة أن العلاج يكلف
        عُشر ما يفعله في الغرب. يتوفر جراحون خاصون للأعضاء الفردية. يقدم جراحو
        التجميل المشهورون العلاج لسرطان الرأس والعنق وسرطان الثدي والأورام
        الخبيثة الأخرى. تشمل التسهيلات المقدمة العلاج عن بعد والذي يشمل محطات
        عمل المحاكاة لضمان الدقة العالية والسلامة أثناء العلاج في المسرع الخطي
        أو آلات الكوبالت ، والعلاج العضدي وأنظمة التخطيط ثلاثية الأبعاد. في
        جراحة العظام ، تتم ممارسة تقنية إليزاروف لعلاج تشوهات الأطراف وتقصير
        الأطراف وتشوهها
      </p>
    </div>
  );
}

export default BoneMarrowTransplant;
