import React from "react";
import forms from "./images/Forms.png";
import styles from "./Assistance.module.css";
import emailjs from "emailjs-com";

function Assistance() {
  const HandleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("btn").value = "تم إرسال النموذج";

    emailjs.sendForm('service_l0fyhn9','template_jns18j6',e.target,'user_Otxk1ZNu8U1u095uL0LCs')
    .then(res=>{
      console.log(res);
    }).catch(err=> console.log(err));

    
    var x = document.getElementsByClassName("inputfield");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].value = "";
    }

  };
  return (
    <div className={styles.container}>
      <div className={styles.imgpart}>
        <img src={forms} alt="ct"></img>
      </div>
      <div className={styles.descpart}>
        <h2>املئ هذا النموذج</h2>
        <p>
          املأ هذا النموذج وسنتلقى التفاصيل الأساسية الخاصة بك ، بعد تلقي
          التفاصيل الخاصة بك ، سوف نتصل بك لمناقشة الخطوة التالية من العملu
        </p>
      </div>
      <div className={styles.textpart}>
        <form className={styles.form} onSubmit={HandleSubmit}>
          <label>اسم</label>
          <input type="text" name="name" className="inputfield"></input>
          <label>عنوان البريد الالكترونى</label>
          <input type="email" name="user_email" className="inputfield"></input>
          <label>بلد</label>
          <input type="text" name="country" className="inputfield"></input>
          <label>مدينة</label>
          <input type="text" name="city" className="inputfield"></input>
          <label>رقم التليفون</label>
          <input type="text" name="number" className="inputfield"></input>
          <input
            type="submit"
            value="إرسال"
            className={styles.submitbtn}
            id="btn"
          ></input>
          {/* <button >Submit</button> */}
        </form>
      </div>
    </div>
  );
}

export default Assistance;
