import React from "react";
import styles from "./styles.module.css";

function KidneyTransplantation() {
  return (
    <div className={styles.container}>
      <h1>Kidney Transplant</h1>
      <p>
        الشيء الرئيسي الذي يجب مراعاته هو أن تكون الكلية الجديدة متوافقة مع
        المريض ويتم تحديد ذلك عن طريق اختبارات الدم. الآن هذا يقودنا إلى مناقشة
        زرع الكلى في الهند. أصبح زرع الكلى في الهند الآن أمرًا شائعًا جدًا. وهذا
        من شأنه أن يفاجئ الكثيرين ممن يتذكرون الهند منذ أيامها الخوالي
      </p>
      <p>
        في الأزمنة القديمة ، كان يُنظر إلى الهند على أنها بيت من الجحيم للأمراض
        التي تتناقض بشكل صارخ مع الصورة التي تحملها الآن والتي تعتبر ملاذًا
        للعلاج. اليوم ينظر الناس من جميع أنحاء العالم إلى الهند كمكان مثالي
        للسياحة العلاجية. نتيجة لهذه التغييرات التي حدثت في الهند وكذلك في
        مفاهيم الناس عنها ، أصبحت الآن مركزًا للعلاج الطبي للمرضى من جميع أنحاء
        العالم
      </p>
      <p>
        وعلى عكس الأوقات السابقة عندما كان يُنظر إلى الهند كمركز للعلاج
        الأيورفيدا أو العلاج الروحي اليوم ، يُنظر إليها على أنها مركز لجميع
        أنواع العلاجات الحديثة ونتيجة لذلك لا تزال زراعة الكلى في الهند مسألة
        تفاجأ بها
      </p>
      <p>
        ولكن هناك بعض القواعد واللوائح التي تحكم أي علاج أو إجراء متعلق بزرع
        الكلى في الهند. للبدء بالمريض أو عائلته ، سيتعين عليهم العثور على متبرع
        على استعداد للتبرع بكليته لإجراء عملية جراحية للمريض. لا تتحمل
        المستشفيات في الهند مسؤولية تحديد المتبرعين. الأمر المهم التالي الذي يجب
        مراعاته هو أنه من الضروري للغاية الحصول على إذن من حكومة الهند قبل إجراء
        أي عملية زرع كلى في الهند هناك. وهذه هي القواعد ، وليست العقبات في الهند
        التي أصبحت المكان المفضل لمعظم السياح الطبيين
      </p>
    </div>
  );
}

export default KidneyTransplantation;
