import React from "react";
import styles from "./Desc.module.css";
import whatsapp from "../images/whatsapp.png";
import call from "../images/call.png";
function Desc() {
  return (
    <div className={styles.container}>
      <h1>الذي نفعله</h1>
      <p>
        نحن نعتني بكل ما تحتاجه أثناء زيارتك الطبية للهند لإقامة بسيطة وخالية من
        الإجهاد. لجعل رحلتك بسيطة قدر الإمكان ، سنساعدك في الاهتمام بجميع
        الإجراءات الشكلية المطلوبة مثل تذاكر السفر والتأشيرة والإقامات وغيرها
        الكثير ، ويمكنك التركيز على علاجك بينما نقوم بالباقي. سيعمل فريقنا
        المتمرس دائمًا على التأكد من أن رحلتك إلى الهند مريحة للغاية وتجربة
        مهدئة مدى الحياة.
      </p>

      <h3>هل لديك أية استفسارات؟ فقط اتصل بنا</h3>
      <div className={styles.links}>
        <a href="https://wa.me/96890609039" target="_blank">
          <img src={whatsapp} alt="whatsapp"></img>
        </a>
        <a href="tel:96890609039">
          <img src={call} alt="call"></img>
        </a>
      </div>
    </div>
  );
}

export default Desc;
