import React from "react";
import styles from "./styles.module.css";

function HerniaOperation() {
  return (
    <div className={styles.container}>
      <h1>Hernia Operation</h1>
      <p>
        يمكن لأي شخص أن يصاب بالمرض - ذكر أو أنثى ، صغيرًا أو كبيرًا ، لائقًا أو
        غير لائق. هناك بعض العوامل التي تزيد من خطر إصابة الشخص بالفتق مثل رفع
        الأوزان الثقيلة ، والسعال المزمن ، والسمنة ، والإجهاد أثناء التبرز أو
        التبول ، والحمل ، والعطس المستمر أثناء الحساسية وضعف قوة العضلات. يمكن
        أن يؤدي الفتق إلى مضاعفات
      </p>
      <p>
        في ظل هذه الظروف المحتملة ، قد يكون هناك حاجة إلى علاج طويل الأمد ، أو
        جراحات بسيطة ثم كبرى. ولكن ، في الوقت الحالي ، لا تتطلب جراحة الفتق أكثر
        من يوم واحد من الإقامة في المستشفى ، وعادة ما تكتمل الجراحة في غضون ساعة
        واحدة. لذلك ، يتم إجراء عملية بسيطة لعلاجه. يمكن إجراء معظم عمليات الفتق
        في الهند بسهولة بواسطة جراحين مؤهلين وفعالين
      </p>
      <p>
        يتم إجراؤها باستخدام تقنية جراحة ثقب المفتاح. تعتبر عمليات الفتق في
        الهند من بين الأفضل في العالم. المرافق جنبًا إلى جنب مع جودة الجراحين
        الذين يجرون العمليات الجراحية تجعل من الهند وجهة مفضلة للخضوع لجراحة
        الفتق. تلبي عمليات الفتق في الهند احتياجات المرضى بأقل تكلفة إضافية
      </p>
      <p>
        كان هناك نمو في السياحة العلاجية وقد أدى ذلك إلى نمو في الجراحة طفيفة
        التوغل مثل جراحة الفتق. تعتبر المرافق في المستشفيات الخاصة ودور رعاية
        المسنين وعيادات عمليات الفتق في الهند من بين الأفضل في العالم ، ومقارنة
        بالعديد من الدول الغربية الأخرى ، فإن المرافق الخاصة هي الأفضل لعمليات
        الفتق في الهند
      </p>
    </div>
  );
}

export default HerniaOperation;
