import React from "react";
import styles from "./styles.module.css";

function EntCare() {
  return (
    <div className={styles.container}>
      <h1>Eyes , Nose and Throat</h1>
      <p>
        هناك أوقات يحدث فيها أن الأشياء الصغيرة في الحياة تفترض حجمًا لم يعد
        صغيراً بعد الآن. ينطبق أيضًا على القضايا المتعلقة بالصحة واللياقة
        البدنية. على الرغم من أنها جميعًا أجزاء مهمة جدًا من الجسم ، إلا أننا
        نميل إلى إعطاء أهمية أقل للمشاكل المتعلقة بالأذن والأنف والحنجرة ، مثل
        مشاكل الأنف والأذن والحنجرة. لكن الاعتبار يتغير عندما يتعين علينا مواجهة
        مشاكل تتعلق بالأنف والأذن والحنجرة والتي تكون خطيرة إلى حد ما
      </p>
      <p>
        فكر في حالة يكون فيها للزوجين طفل أصم تمامًا. غالبًا ما تعتبر مثل هذه
        الحالات غير قابلة للشفاء ويترك الطفل مدى الحياة من الإعاقة والإذلال. في
        حال كنت تعاني بالفعل من مشكلة خطيرة في الأنف والأذن والحنجرة ، فقد حان
        الوقت للسفر إلى الهند واخترت رعاية الأنف والأذن والحنجرة في الهند
      </p>
      <p>
        هناك عدة أنواع من الاعتلالات والإعاقات التي تندرج تحت تخصص الأنف والأذن
        والحنجرة. بمجرد وصولك إلى الهند ، ستحصل على خدمة مرضية لكل من هؤلاء. بعض
        مشاكل الأنف والأذن والحنجرة وعلاجها والتي تكون فيها خبرة المتخصصين
        الطبيين الهنود هي:
      </p>
      <ul>
        <li>
          تنظير الجيوب الأنفية: مشاكل الأنف والأذن والحنجرة المتعلقة بالبلعوم
          الأنفي والغدة النخامية
        </li>
        <li>طب الأذن والأنف والحنجرة للأطفال: متعلق بالصمم</li>
        <li>
          غرسة القوقعة الصناعية: للحالات التي يكون فيها الصمم (للبالغين و
          الأطفال) خطيرة جدا
        </li>
      </ul>
      <p>
        بصرف النظر عن هذه ، هناك أشكال أخرى عديدة من المشاكل ذات الصلة التي
        يتخصص فيها الهنود. هذا هو السبب في أن الكثيرين كل عام يتألمون من السفر
        على طول الطريق إلى الهند حتى يتمكنوا من الاستفادة من رعاية الأنف والأذن
        والحنجرة في الهند. هناك أيضًا عدة أنواع من جراحات الأنف والأذن والحنجرة
        مثل جراحة الحنجرة الدقيقة وجراحة الأذن الوسطى الترميمية وجراحة العصب
        الوجهي والجراحة الصوتية ومجموعة من العمليات الجراحية الأخرى التي يمكنك
        الاستفادة منها في أيدي جراحي الأنف والأذن والحنجرة ذوي المهارات العالية
        في الهند. والسبب في أن الكثير من الناس يأتون إلى الهند سنويًا لرعاية
        الأنف والأذن والحنجرة في الهند هو حقيقة أن جودة عالية من علاج الأنف
        والأذن والحنجرة متوفرة مقابل تكلفة أقل من نصف ما قد تحتاجه في أي مكان
        آخر. لذا ، وجهتك واضحة تمامًا
      </p>
    </div>
  );
}

export default EntCare;
