import React from "react";
import styles from "./Whyus.module.css";

function Whyus() {
  return (
    <div className={styles.container}>
      <div className={styles.services}>
        <h1>خدماتنا</h1>
        <p>
          تهتم مؤسسة إحسان للسياحة الطبية بكل ما تحتاجه أثناء زيارتك الطبية
          للهند لإقامة بسيطة وخالية من الإجهاد. لجعل رحلتك بسيطة قدر الإمكان ،
          سنساعدك في الاهتمام بجميع الإجراءات الشكلية المطلوبة بحيث يمكنك
          التركيز على علاجك بينما نقوم بالباقي
        </p>
        <h2>خدمة التوصيل من وإلى المطار</h2>
        <p>
          توفر درة إحسان للسياحة الطبية لمرضاها ومرافقيهم خدمة الاستقبال في
          المطار عند وصولهم إلى الهند والتوصيل عند إكمالهم للعلاج ، مرفق مجاني
          تمامًا. ستقلك سيارة أجرة من المطار وتنقلك إلى الفندق أو المستشفى أو
          دار الضيافة وفقًا للخطة
        </p>
        <h2>المترجمون في البيت</h2>
        <p>
          لكسر حاجز اللغة أثناء زيارتك ، تقوم درة إحسان للسياحة الطبية بتعيين
          مترجم لمرافقتك في زياراتك للطبيب ، وبهذه الطريقة يمكنك التعبير عن
          حالتك بشكل كامل وسيفهم الأطباء مشكلتك بشكل كامل ويمكنهم تقديم أفضل
          علاج لك
        </p>
        <h2>السفر والإقامة والمواصلات</h2>
        <p>
          بالتعاون مع كبار مزودي خدمات السفر درة إحسان السياحة الطبية تساعدك في
          تلبية جميع احتياجات السفر الخاصة بك وتضمن لك رحلة سهلة وممتعة. إذا كنت
          ترغب في تسليم مسؤولية إجراء ترتيبات السفر الخاصة بك ، فيمكننا القيام
          بذلك نيابة عنك ، مع ضمان تنسيق مواعيد سفرك مع زيارتك للمستشفى
        </p>
        <h2>المعالم السياحية والسياحة</h2>
        <p>
          كما تقدم لك مؤسسة إحسان للسياحة الطبية باقات سياحة ومشاهدة المعالم
          السياحية إلى وجهات مختلفة في الهند. يمكن أن يمنحك ذلك فرصة لاستكشاف
          الثقافة المحلية والطعام وطريقة الحياة ويوفر لك الفرصة لاستكشاف البلد
          الجميل
        </p>
      </div>
      <div className={styles.whyus}>
        <h1>لماذا نحن</h1>
        <ul>
          <li>أحدث المرافق الطبية</li>
          <li>أخصائيو الرعاية الصحية المشهورون</li>
          <li>لا يوجد وقت انتظار للاستفادة من الخدمات الطبية</li>
          <li>
            مجموعة متنوعة من مستشفيات الشركات للاختيار من بينها في جميع أنحاء
            الهند
          </li>
          <li>
            تكلفة منخفضة - جزء بسيط من تكلفة الجراحة الخاصة في مختلف البلدان
            الأخرى
          </li>
          <li>
            مخاطر منخفضة - أعلى معايير الرعاية والجراحة السريرية في العالم
          </li>
          <li>أدنى معدلات الإصابة</li>
          <li>خدمة دعم كاملة للمرضى - خدمات على مدار الساعة</li>
          <li>الاستشارات المتخصصة - المسح والاستشارات في وقت قصير</li>
          <li>نسعى باستمرار للحفاظ على أعلى مستويات الخدمة</li>
          <li>السعي للتميز في الرعاية الصحية</li>
          <li>نهج بسيط ومباشر تجاه المرضى</li>

          <li>سرعة عالية في توصيل خدماتنا</li>
        </ul>
      </div>
    </div>
  );
}

export default Whyus;
