import React from "react";
import styles from "./Hero.module.css";

import logo from "../images/logowithtext.png";

function Home() {
  return (
    <div className={styles.container}>
    <div className={styles.imgpart}>
        <img src={logo} alt="ct"></img>
      </div>
      <div className={styles.textpart}>
        <h1>سافر إلى الهند للحصول على علاج أفضل بنصف السعر</h1>

        <h3>ما نقدمه</h3>
        <ul>
          <li>الوصول إلى أفضل المرافق الطبية</li>
          <li>استشارة من أمهر الأطباء</li>
          <li>معالجة خالية من المتاعب</li>
          <li>السفر والإقامة والتأشيرة</li>
          <li>قيمة المال</li>
          <li>مترجم للتواصل الفعال</li>
        </ul>
      </div>
      
    </div>
  );
}

export default Home;
