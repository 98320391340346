import React from "react";
import styles from "./styles.module.css";

function NeuroSurgery() {
  return (
    <div className={styles.container}>
      <h1>Neurology</h1>
      <p>
        هناك جودة عالية للجراحة المجهرية لجميع أنواع جراحات الدماغ الممكنة مثل
        أورام الغدة النخامية والأورام الصوتية وأورام الجمجمة وأورام البطين
        والأورام الدبقية وما إلى ذلك. هناك أيضًا العديد من المؤسسات الطبية التي
        تقدم جراحة الأعصاب في الهند وتتخصص في العديد من جراحات الأوعية الدموية
        مثل تلك الخاصة بتمدد الأوعية الدموية من جميع الأحجام ، وتجاوز الشرايين ،
        واستئصال باطنة الشريان السباتي ، وغيرها من المضاعفات العصبية ذات الصلة
      </p>
      <p>
        الأمر نفسه ينطبق أيضًا على أشكال مختلفة من جراحات العمود الفقري. سبب آخر
        لهذه الشعبية الهائلة لجراحة الأعصاب في الهند هو المستوى الكبير لجراحة
        أعصاب الأطفال التي قد يفكر فيها المرء في سبب اختيار الهند والسفر لمسافات
        طويلة لمثل هذا العلاج المعقد مثل جراحة الأعصاب. الجواب بسيط وواضح. أدى
        الازدهار المفاجئ في صناعة الصحة في البلاد إلى وجود عدد كبير من الممارسين
        الطبيين ذوي الكفاءة العالية. والتكلفة التي ينطوي عليها العلاج الشامل إيث
        هي أيضًا تنافسية للغاية. نتيجة لذلك ، فأنت بحاجة إلى أقل من 50٪ مما قد
        تحتاج إلى إنفاقه في مكان آخر. وبالتالي ، أصبحت جراحة الأعصاب في الهند
        شائعة جدًا
      </p>
    </div>
  );
}

export default NeuroSurgery;
