import React from "react";
import styles from "./styles.module.css";

function DiabetesTreatment() {
  return (
    <div className={styles.container}>
      <h1>Diabetes Treatment</h1>
      <p>
        العديد من العلاجات للأمراض المختلفة التي لم تكن ممكنة في الماضي منتشرة
        الآن هناك. على سبيل المثال ، لم يُسمع عن علاج مرض السكري في الهند في
        الأوقات الماضية. ولكن قبل مناقشة أي موضوع آخر حول علاج مرض السكري في
        الهند ، يتعين علينا أولاً اكتساب نظرة شاملة وشاملة ومعرفة حول مرض السكري
        وعلاجه.
      </p>
      <p>
        إذا كان على المرء أن يعرف مرض السكري بأسلوب قصير جدًا وموجز ، فسيتعين
        على المرء أن يقول ببساطة إن مرض السكري يعني زيادة الجلوكوز في الدم. هذا
        ما يشير إليه عامة الناس بمستوى السكر في الدم. علاجات مرض السكري كثيرة
        جدا. في معظم الحالات ، يتم إعطاء جرعات من الأنسولين من أجل تصحيح
        المشكلة. هذا العلاج معقد بعض الشيء. لكن هذا لا يعني أن الأطباء في الهند
        لن يكونوا قادرين على ترتيب علاج مرض السكري في الهند للأشخاص المتواجدين
        على شواطئ الهند على أمل أن يتحسنوا.
      </p>
      <p>
        أصبح علاج مرض السكري في الهند شائعًا لدرجة أن الأشخاص من الخارج يأتون
        إلى هنا لتلقي العلاج عندما يمكنهم الحصول على علاج مماثل في مراكز طبية
        مختلفة بالقرب من منازلهم. قد يكون أحد الأسباب الرئيسية وربما الأكثر
        إقناعًا لذلك هو النفقات الباهظة لأسباب الرعاية الصحية في بلدهم
      </p>
      <p>
        نظرًا لأسعار الصرف الحالية وأيضًا بسبب عدة عوامل أخرى ، يصبح علاج مرض
        السكري في الهند رخيصًا جدًا مقارنة بالدول الأخرى. ويمكن أيضًا التأكد
        بسهولة من الجودة والعلاج بالإضافة إلى الرعاية اللاحقة للعمليات الجراحية
      </p>
      <p>
        ولكن إذا كنت ترغب في اختيار علاج مرض السكري في الهند ، فعليك أن تتذكر
        دائمًا أنه سيتعين عليهم الامتثال لقواعد ولوائح معينة واتباعها من أجل
        الحصول على إذن للعلاج في الهند. سيتعين عليهم أولاً الاتصال بطبيب ذائع
        الصيت عبر ممثلين طبيين في بلدهم. بعد القيام بذلك سيتعين عليهم تحديد
        مشاكلهم وتقديرها. بعد انتهاء العلاج هناك العديد من الفرص للتعافي. ولهذا
        السبب ، قفزت السياحة العلاجية ببساطة في الهند وجعلتها لاعبًا عالميًا في
        هذا المجال
      </p>
    </div>
  );
}

export default DiabetesTreatment;
